import React from "react";
import BxBrandSlider from "./BrandSlider";
import BxProductSlider from "./ProductSlider";
import BxTopicTileGrid from "./TopicTileGrid";
import BxBlogTileGrid from "./blog/TileGrid";
import BxDesktopRow from "./DesktopRow";
import BxHighlightTileGrid from "./HighlightTileGrid";
import BxHeroTile from "./HeroTile";
import BxHeroTileWrapper from "./HeroTileWrapper";
import single from "../../utils/single";

interface BlockProps {
    narrative: any;
    dailyDeal?: any;
}

const components: Record<string, React.ComponentType<BlockProps>> = {
    BrandSlider: BxBrandSlider,
    ProductSlider: BxProductSlider,
    TopicTileGrid: BxTopicTileGrid,
    BlogTileGrid: BxBlogTileGrid,
    DesktopRow: BxDesktopRow,
    HighlightTileGrid: BxHighlightTileGrid,
    HeroTile: BxHeroTile,
    HeroTileWrapper: BxHeroTileWrapper,
};

export default function BxHomeNarrativeBlock({ block, dailyDeal }: { block: any; dailyDeal?: any; }) {
    const name = single(block.name);
    const DynamicComponent = components[name];
    if (!DynamicComponent) {
        console.warn(`No component found for block with name ${name}`);
        return <React.Fragment />;
    }

    const props: BlockProps = {
        narrative: block
    }

    if (DynamicComponent === BxDesktopRow) {
        props.dailyDeal = dailyDeal;
    }

    return <DynamicComponent {...props} />;
}
