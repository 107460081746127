import React from "react";
import Image from "next/image";
import Link from "../link/Link";
import ensureArray from "../../utils/ensureArray";
import {convertBxAttributes} from "../../utils/bxAttributes";
import {BxRichText} from "./RichText";
import {imageSizes} from "../../utils/imageSizes";
import single from "../../utils/single";

interface Props {
    narrative: any;
}

function TileImage({block}: { block: any }) {
    return <Image
        src={single(block.image)}
        width={380}
        height={279}
        sizes={imageSizes({
            xs: 'calc(50vw - 16px)',
            sm: '254px',
            md: '227px',
            lg: '307px',
            xl: '367px',
            xxl: '380px'
        })}
        alt={single(block['alt-text'])}
        className="object-fit-contain h-auto w-100 rounded-4"
    />;
}

export default function BxHighlightTileGrid({narrative}: Props) {
    const blocks = ensureArray(narrative.blocks)

    return <div {...convertBxAttributes(narrative, 'container mt-4')}>
        {narrative.title && (
            <div className="row">
                <div className="col-12">
                    <h2 className="mb-3">{narrative.title}</h2>
                    <hr className={"primary-color"}/>
                </div>
            </div>
        )}

        {blocks.map((block: any, index: number) => (
            <Link {...convertBxAttributes(block, 'row mb-2 d-md-none g-2')} href={block.link} key={index}>
                <div key="image" className="col-6">
                    <TileImage block={block}/>
                </div>
                <div key="title" className="col-6">
                    <BxRichText content={block.title}/>
                </div>
            </Link>
        ))}

        <div className="row g-2 d-none d-md-flex">
            {blocks.map((block: any, index: number) => (
                <Link {...convertBxAttributes(block, 'col-4')} href={block.link} key={index}>
                    <TileImage key="image" block={block}/>
                    <div key="title" className="mt-1">
                        <BxRichText content={block.title}/>
                    </div>
                </Link>
            ))}
        </div>
    </div>
}
